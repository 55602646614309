export enum Tool {
	ANTHROPIC = 'Anthropic',
	ATLASGO = 'AtlasGo',
	AWS = 'Aws',
	BUBBLE = 'Bubble',
	BUFFER = 'Buffer',
	CIRCLESO = 'CircleSo',
	CREATIFY = 'Creatify',
	DATAFORSEO = 'Dataforseo',
	ELESTIO = 'Elestio',
	EVERFLOW = 'Everflow',
	'GOOGLE-ANALYTICS' = 'Google Analytics',
	'GOOGLE-DOCS' = 'Google Docs',
	'GOOGLE-FORMS' = 'Google Forms',
	'GOOGLE-SHEETS' = 'Google Sheets',
	'GOOGLE-SLIDES' = 'Google Slides',
	HUBSPOT = 'HubSpot',
	KLAVIYO = 'Klaviyo',
	LLANA = 'Llana',
	MYSQL = 'MySQL',
	N8N = 'N8n',
	NESTJS = 'Nestjs',
	NUXTJS = 'Nuxtjs',
	OPENAI = 'Openai',
	RECHARGE = 'Recharge',
	SENDGRID = 'Sendgrid',
	SHIPBOB = 'Shipbob',
	SHOPIFY = 'Shopify',
	SLACK = 'Slack',
	SPYFU = 'Spyfu',
	VUEJS = 'Vuejs',
	XERO = 'Xero',
}
